<template>
  <b-card class="shop-store-reviews">
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="reviewsRef"
          :columns="reviewsColumns"
          :total-column="'response.responseData.total'"
          :data-column="'response.responseData.data'"
          :api-endpoint="getApiEndpoint"
        />
      </b-col>
    </b-row>
    <b-modal
      v-model="showReviewModal"
      :no-close-on-backdrop="true"
      :title="'Revert Review'"
      ok-only
      hide-footer
      :ok-title="$t('Save Changes')"
      @hidden="showReviewModal = false"
      @ok="revertTheReview"
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group :label="$t('Revert Review')">
            <b-form-input
              v-model="dataInfo.review"
              :class="{ 'is-invalid': !dataInfo.review && isDataSubmitted }"
              type="text"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <modal-footer
        :show-cancel="false"
        @ok="revertTheReview"
      />
    </b-modal>
    <b-modal
      v-model="showReviewReply"
      :no-close-on-backdrop="true"
      :title="'Store Reply'"
      hide-footer
      @hidden="showReviewReply = false"
    >
      <b-row v-if="activeReplyReview && activeReplyReview.review_response">
        <b-col
          cols="12"
          md="12"
        >
          <p>{{ $t('Reply') }}: {{ activeReplyReview.review_response.review }}</p>
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <p>{{ $t('Rating') }}:
            <ul class="unstyled-list list-inline">
              <li
                v-for="star in 5"
                :key="star"
                class="ratings-list-item"
                :class="{ 'ml-25': star - 1 }"
              >
                <feather-icon
                  icon="StarIcon"
                  size="16"
                  :class="[{ 'fill-current': star <= activeReplyReview.review_response.rate }, star <= activeReplyReview.review_response.rate ? 'theme-color' : 'text-muted']"
                />
              </li>
            </ul></p>
        </b-col>
      </b-row>
      <b-row v-else>
        <div class="col-md-12 text-center">
          <p>{{ $t('No reply given to the user') }}</p>
        </div>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormGroup, BFormInput, BModal } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import ModalFooter from '@/@core/components/modal-footer/ModalFooter.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useStoreUi } from './useStore'

export default {
  components: {
    BCard,
    BRow,
    BModal,
    BCol,
    ModalFooter,
    GoodDataTable,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      showReviewModal: false,
      activeReplyReview: null,
      showReviewReply: false,
      isDataSubmitted: false,
      dataInfo: {
        review: '',
        rate: '',
      },
      reviewsColumns: [
        {
          label: 'Review',
          field: 'review',
        },
        {
          label: 'Customer Name',
          field: 'user.first_name',
          rField: 'first_name',
          useResolver: true,
          renderer: props => `${props.user.first_name} ${props.user.last_name}`,
        },
        {
          label: 'Reference Number',
          field: 'booking_id',
          rField: 'booking_id_text',
          useResolver: true,
          renderer: props => {
            if (props.provider) {
              return props.provider.booking_id
            }
            if (props.order) {
              return props.order.store_order_invoice_id
            }
            return ''
          },
        },
        {
          label: 'Review From',
          field: 'parent_id',
          rField: 'parent_id_text',
          useResolver: true,
          // eslint-disable-next-line no-nested-ternary
          renderer: props => (props.parent_id ? (window.SuperApp.getters.isStore() ? 'SHOP' : 'PROVIDER') : 'CUSTOMER'),
        },
        {
          label: 'Rating',
          field: 'rate',
          useHtml: true,
          excelHtml: props => `Rating: ${props.rate}`,
          renderer: props => {
            const htmlString = []
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < 5; index++) {
              htmlString.push(
                `<li class="ratings-list-item ${
                  index === 0 ? '' : 'ml-25'
                }"><svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star ${
                  index < props.rate ? 'fill-current text-warning' : ''
                }"><polygon data-v-15eba8c6="" points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg></li>`,
              )
            }
            return `<ul class="unstyled-list list-inline">${htmlString.join('')}</ul>`
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('reply'),
              text: 'Reply',
              action: props => {
                this.replyUser(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('view'),
              text: 'View Reply',
              action: props => {
                this.viewReply(props)
              },
            },
          ],
        },
      ],
    }
  },
  computed: {
    getApiEndpoint() {
      return window.SuperApp.getters.isStore() ? '/shop/store/reviews' : `/provider/reviews/${window.SuperApp.getters.userInfo().id}`
    },
    isStore() {
      return window.SuperApp.getters.isStore()
    },
  },
  methods: {
    viewReply(payload) {
      this.activeReplyReview = payload
      this.showReviewReply = true
    },
    replyUser(payload) {
      this.activeReplyReview = payload
      Object.keys(this.dataInfo).forEach(item => {
        this.dataInfo[item] = ''
      })
      this.showReviewModal = true
      this.isDataSubmitted = false
    },
    deleteReviews(payload) {
      const { deleteStoreReview } = useStoreUi()
      showLoader()
      deleteStoreReview(payload)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.reviewsRef.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    revertTheReview() {
      this.isDataSubmitted = true
      setTimeout(() => {
        if (window.SuperApp.validations.anyErrorInModal()) {
          return
        }
        this.showReviewModal = false
        this.isDataSubmitted = false
      const { revertReview } = useStoreUi()
      showLoader()
      const formData = new FormData()
      formData.append('store_id', this.activeReplyReview.store.id)
      formData.append('user_id', this.activeReplyReview.user.id)
      formData.append('review', this.dataInfo.review)
      formData.append('rate', this.activeReplyReview.rate)
      formData.append('parent_id', this.activeReplyReview.id)

      revertReview(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.reviewsRef.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
      })
    },
  },
}
</script>
